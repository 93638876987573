import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import {media} from "./variables";

const BlogArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  ${media.tablet`
    grid-template-columns: 1fr;
  `}
`

const BlogPostDiv = styled.a`
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
`

const BlogCardName = styled.h2`
  font-size: 20px !important;
  height: 60px;
  
  ${media.tablet`
    font-size: 18px !important;
  `}
`

const BlogCardArea = styled.div`
  padding: 20px;
`

const BlogCardExcerpt = styled.p`
  color: #2b2523;
`

const BlogCardBottom = styled.div`
  padding: 20px;
`

const BlogInfo = styled.p`
  color: #333333;
  font-size: 14px;
  margin-left: 60px;
  margin-top: -24px;
`

const AuthorGrid = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-gap: 10px;
`

const AuthorImg = styled.img`
  border-radius: 50%;
`

const AuthorName = styled.p`
  color: #333333;
  font-size: 18px;
`

class BlogAuthor extends React.Component {
  render() {
    const data = this.props;
    let authorName, authorImg = ""

    switch (data.author) {
      case "KV":
        authorName = "Kamil Vávra";
        authorImg = "/img/avatars/kamil_vavra.jpg";
        break;
      case "JM":
        authorName = "Ján Masarik";
        authorImg = "/img/avatars/jan_masarik.jpg";
        break;
      case "MŽ":
        authorName = "Martin Žember";
        authorImg = "/img/avatars/martin_zember.jpg";
        break;
      case "PS":
        authorName = "Petr Skyva";
        authorImg = "/img/avatars/petr_skyva.png";
        break;
      case "FH":
        authorName = "Filip Holec";
        authorImg = "/img/avatars/fifo_profile.jpg";
        break;
      default:
        authorName = "TunaSec";
        authorImg = "/img/avatars/petr_skyva.png"
    }

    return(
        <AuthorGrid>
          <AuthorImg src={authorImg} alt={`Autor článku ${authorName}`}/>
          <AuthorName>{authorName}</AuthorName>
        </AuthorGrid>
    )
  }
}

export class BlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <BlogArea>
        {posts &&
          posts.map(({ node: post }) => (
              <BlogPostDiv key={post.id} href={post.fields.slug}>
                <article className={`blog-list-item tile is-child`}>
                  <PreviewCompatibleImage imageInfo={post.frontmatter.featuredimage} style={{height:"180px"}} title={post.frontmatter.title}/>
                  <BlogCardArea>
                    <BlogCardName>{post.frontmatter.title}</BlogCardName>
                    <BlogCardExcerpt>{post.excerpt}</BlogCardExcerpt>
                  </BlogCardArea>
                  <BlogCardBottom>
                    <BlogAuthor author={post.frontmatter.author}/>
                    <BlogInfo>{post.frontmatter.date} | {post.timeToRead} min. čtení</BlogInfo>
                  </BlogCardBottom>
                </article>
              </BlogPostDiv>
            // <div className="is-parent column is-2" key={post.id}>
            //   <article
            //     className={`blog-list-item tile is-child box notification ${
            //       post.frontmatter.featuredpost ? 'is-featured' : ''
            //     }`}
            //   >
            //     <header>
            //       {post.frontmatter.featuredimage ? (
            //         <div className="featured-thumbnail">
            //           <PreviewCompatibleImage
            //             imageInfo={{
            //               image: post.frontmatter.featuredimage,
            //               alt: `featured image thumbnail for post ${post.frontmatter.title}`,
            //             }}
            //           />
            //         </div>
            //       ) : null}
            //       <p className="post-meta">
            //         <Link
            //           className="title has-text-primary is-size-4"
            //           to={post.fields.slug}
            //         >
            //           {post.frontmatter.title}
            //         </Link>
            //         <span> &bull; </span><br />
            //
            //       <div id="post_info">
            //         <small>{post.frontmatter.date} | {post.timeToRead} min. čtení | Autor: {post.frontmatter.author}</small>
            //       </div>
            //       </p>
            //     </header>
            //     <p>
            //       {post.excerpt}
            //     <br/>
            //     {/*<hr style={{border: '1px dashed'}} />
            //     <small>Categories:</small>
            //     <br/>
            //     <small> Tags: {post.frontmatter.tags.map(tag => (<Link to={`/tags/${kebabCase(tag)}/`}>{tag} / </Link>))}</small>*/}
            //     <br/>
            //     <br />
            //     <Link className="button" to={post.fields.slug}>
            //       Pokračovat v čtení →
            //     </Link>
            //
            //     </p>
            //   </article>
            // </div>
          ))}
      </BlogArea>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 120)
              id
              fields {
                slug
              }
              timeToRead
              frontmatter {
                author
                tags
                title
                templateKey
                date(formatString: "MMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, maxHeight: 200, cropFocus: CENTER, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)